#projects-container {
    height: 200vh;
    width: 100vw;
    background-color: var(--color-background);
    position: relative;
}

#projects-overview-container {
    width: 70vw;
    height: 100%;
    position: absolute;
    top: 15vh;
    left: 15vw;
    display: flex;
    overflow: hidden;
    flex-direction: column;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
   #projects-overview-container {
    overflow: visible;
    width: 80vw;
    left: 0vw;
   }
}