#contact-form-container {
    width: 100%;
    height: 100%;
    z-index: 999;
    position: relative;
}

#contact-form-container form{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

#contact-form-name-container input{
    border: 1px solid grey;
    border-radius: 3%;
    width: 100%;
    background-color: var(--color-background);
    color: var(--color-secondary-color);
}

#contact-form-name-container {
    width: 100%;
}

#contact-form-email-container {
    width: 100%;
}

#contact-form-message-container {
    width: 100%;
}


#contact-form-email-container input{
    border-radius: 3%;
    border: 1px solid grey;
    width: 100%;
    background-color: var(--color-background);
    color: var(--color-secondary-color);
}
#contact-form-message-container input{
    border-radius: 3%;
    border: 1px solid grey;
    width: 100%;
    height: 75px;
    background-color: var(--color-background);
    color: var(--color-secondary-color);
}

#contact-form-submit-button {
    width: fit-content;
}
