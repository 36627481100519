.socialbar-container {
    width: 100vw;
    height: fit-content;
    position: absolute;
    bottom: 40px;
    z-index: 777;
}

.fixed {
    position: fixed;
    top: 32px;
    bottom: auto;
    z-index: 888;
}

#socialbar-list {
    display: flex;
    justify-content: center;
    list-style: none;
}

#socialbar-list li {
    width: 50px;
}

.hide-email {
    display: none;
    position: relative;
}

.show-email {
    display: inline-block;
    padding-left: 20px;
}

#socialbar-email {
    display: flex;
    align-items: flex-end;
}

#copy-email {
    margin-left: 8px;
    width: 20px;
    height: 20px;
    z-index: 999;
    position: absolute;
}

#socialbar-list--item-email {
    display: flex;
    align-items: flex-end;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .socialbar-container {
        position: fixed;
        top: 0px;
        bottom: auto;
        z-index: 998;
        background-color: rgb(6, 6, 6);
        height: 80px;
        padding-top: 32px;
    }

    #socialbar-list {
        justify-content: flex-start;
        padding-left: 20px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) { 
    #socialbar-list {
        font-size: 1.6rem;
    }

    #socialbar-list li {
        width: 80px;
    }

    .show-email {
        position: absolute;
        bottom: 80px;
        right: 100px;
    }
}