#page-skills-container {
    background-color: var(--color-background);
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

#skills-inner-container {
    height: 70vh;
    width: 70vw;
    display: flex;
    flex-direction: column;
    position: relative;
}

#skills-section-container {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: space-between;
}

.skills-section {
    text-align: justify;
    width: 200px;
    position: relative;
}

.middle {
    margin-left: 10vw;
}

.skills-section div{
    display: flex;
    align-items: flex-end;
    height: 100px;
}

#button-projects {
    border: 1px solid var(--color-secondary);
    padding: 4px;
    text-decoration: none;
    width: fit-content;
    position: absolute;
    bottom: 0;
    right: 0;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    #skills-inner-container {
        height: 60vh;
    }

    #skills-inner-container h1 {
        margin-bottom: 60px;
    }

    .skills-section {
        height: 50%;
        width: 45%;
    }

    .skills-section h2 {
        margin-bottom: 30px;
    }

    .middle {
        margin-left: 0;
    }

    .mobile-break {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
}