#page-landing-container {
    width: 100vw;
    height: 100vh;
    background-color: var(--color-background);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

#page-landing-content-container {
    font-size: 4rem;
    font-weight: bold;
    position: relative;
    height: 70vh;
    width: 70vw;
    z-index: 888;
    color: var(--color-secondary);
    overflow: hidden;
    display: flex;
}

#page-landing-name {
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: end;
    width: 15%;
    margin-top: 11%;
    word-break: break-all;
    line-height: .9;
    transition-property: transform;
    transition-duration: .15s;
}

#page-landing-content-inner-container {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100%;
}

#page-landing-surname {
    height: 15%;
    display: flex;
    align-items: flex-end;
}

.page-landing-surname-letter {
    width: 20%;
}

.left {
    text-align: start;
}

.right {
    text-align: end;
}

.center {
    text-align: center;
}


#page-landing-description {
    position: absolute;
    bottom: 10%;
    right: 0;
    font-weight: normal;
    font-size: 1.3rem;
}

#page-landing-image {
    max-height: 70%;
    overflow: hidden;
    object-fit: cover;
}


.page-landing-title-name-letter {
    display: inline;
}


.page-landing-title-name-letter:after {
    font-weight: 200;
    font-size: 20px;
    margin-left: 10px;
}

.page-landing-title-name-letter-hide {
    transform: scaleY(0);
    transform-origin: 0 50%;
    transition: all 1s ease;
    max-height: 0;
    font-size: 2rem;
    line-height: 1;
}


.page-landing-title-name-letter-hide:nth-of-type(1) {
    transition-delay: 100ms, 100ms, 100ms;
    transition-property: max-height, opacity, transform;
}
.page-landing-title-name-letter-hide:nth-of-type(2) {
    transition-delay: 200ms;
}
.page-landing-title-name-letter-hide:nth-of-type(3) {
    transition-delay: 300ms;
}
.page-landing-title-name-letter-hide:nth-of-type(4) {
    transition-delay: 400ms;
}
.page-landing-title-name-letter-hide:nth-of-type(5) {
    transition-delay: 500ms;
}
.page-landing-title-name-letter-hide:nth-of-type(6) {
    transition-delay: 600ms;
}
.page-landing-title-name-letter-hide:nth-of-type(7) {
    transition-delay: 700ms;
}
.page-landing-title-name-letter-hide:nth-of-type(8) {
    transition-delay: 800ms;
}
.page-landing-title-name-letter-hide:nth-of-type(9) {
    transition-delay: 900ms;
}
.page-landing-title-name-letter-hide:nth-of-type(10) {
    transition-delay: 1000ms;
}
.page-landing-title-name-letter-hide:nth-of-type(11) {
    transition-delay: 1100ms;
}


#page-landing-name:hover .page-landing-title-name-letter-hide{
    max-height: 200px;
    transform: scaleY(1);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    html, body {
        overflow-x: hidden;
    }
    
    body {
        position: relative;
        width: 100%;
    }

    #page-landing-container {
        justify-content: flex-start;
        align-items: flex-end;
    }

    #page-landing-name {
        position: absolute;
        top: 11%;
        left: 20px;
        z-index: 888;
        text-align: start;
    }

    #page-landing-surname {
        margin-left: 20px;
    }

    #page-landing-content-container {
        width: 100vw;
        height: 80vh;
    }

    #page-landing-content-inner-container {
        width: 80%;
        position: relative;
    }

    #page-landing-description {
        position: absolute;
        right: 0;
        width: 50%;
    }

    #page-landing-image {
        max-height: 100%;
        width: 100%;
        object-fit: cover;
        object-position:  -120px -120px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    #page-landing-content-container {
        width: 80vw;
        height: 80vh;
        font-size: 5rem;
    }

    #page-landing-description {
        font-size: 1.6rem;
        bottom: 12%;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}