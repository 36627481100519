:root {
    --color-primary: rgb(222, 66, 166);
    --color-secondary: rgb(255, 197, 38);
    --color-background: rgb(8, 8, 9);
}

body {
    overflow-x: hidden;
    position: relative;
    color: var(--color-primary);
    box-sizing: border-box;
    
}

body::-webkit-scrollbar {
    display: none;
}

a {
    color: var(--color-primary);
}


* {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}
