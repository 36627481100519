#page-skills-container {
    background-color: var(--color-background);
    width: 100vw;
    height: 100vh;
    position: relative;
}

#table {
    position: absolute;
    height: 70vh;
    width: 70vw;
    top: 15%;
    left: 15%;
    display: flex;
    z-index: 995;
}

.table-column-1 {
    position: relative;
    height: 100%;
    width: 15%;
}

.table-column-2 {
    position: relative;
    height: 100%;
    width: 70%;
}

.table-row-1 {
    position: relative;
    width: 100%;
    height: 15%;
}

.table-row-2 {
    position: relative;
    width: 100%;
    height: 70%;
}

.table-row-1 h1 {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
}

.table-column-2 {
    padding-left: 25vw;
}


.page-skills-icon {
    width: 200px;
}

.table-column-2 img {
    margin-left: 25vw;
}

.table-row-2 p {
    text-align: justify;
    padding-bottom: 300px;
}

.table-row-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#button-projects {
    border: 1px solid var(--color-secondary);
    padding: 4px;
    text-decoration: none;

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}