#contact-page-container {
    width: 100vw;
    height: 100vh;
    background-color: var(--color-background);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

#contact-container {
    position: absolute;
    width: 70vw;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#contact-container h2 {
    font-size: 2.5rem;
}

.underline {
    text-decoration: underline;
}

.up-down-animation {
    animation: moveUpDown 3s ease-in-out infinite;
}

@keyframes moveUpDown {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-30px);
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    #contact-container h2 {
        font-size: 1.9rem;
    }
}