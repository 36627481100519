#cv-download-button {
    border: 1px solid var(--color-secondary);
    padding: 4px;
    text-decoration: none;
    width: fit-content;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--color-background);
    color: var(--color-primary);
    font-size: 1rem;
    cursor: pointer;
}