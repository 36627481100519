#page-landing-container {
    width: 100vw;
    height: 100vh;
    background-color: var(--color-background);
    position: relative;
}

#page-landing-title-container-table {
    font-size: 2rem;
    position: absolute;
    height: 70vh;
    width: 70vw;
    z-index: 888;
    top: 15vh;
    left: 15vw;
    display: flex;
    color: var(--color-secondary);
}

.page-landing-title-container-column-1 {
    position: relative;
    height: 100%;
    width: 15%;
}

.page-landing-title-container-column-2 {
    position: relative;
    height: 100%;
    width: 70%;
}

.page-landing-title-container-row-1 {
    position: relative;
    width: 100%;
    height: 15%;
}

.page-landing-title-container-row-2 {
    position: relative;
    width: 100%;
    height: 70%;
}

#page-landing-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

#page-landing-title-surname {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
}

#page-landing-title-surname span {
    width: 20%;
}

.page-landing-title-surname-left {
    text-align: start;
}

.page-landing-title-surname-right {
    text-align: end;
}

.page-landing-title-surname-center {
    text-align: center;
}

#page-landing-title-name {
    position: absolute;
    right: 0;
    word-break: break-all;
    width: 50%;
    line-height: .9;
    text-align: end;
    transition-property: transform;
    transition-duration: .15s;
    display: flex;
    flex-direction: column;
    z-index: 777;
}

.page-landing-title-name-letter {
    display: inline;
}


.page-landing-title-name-letter.first:after {
    font-weight: 200;
    font-size: 20px;
    margin-left: 10px;
}

.page-landing-title-name-letter-hide {
    transform: scaleY(0);
    transform-origin: 0 50%;
    transition: all 1s ease;
    max-height: 0;
    font-size: 2rem;
    line-height: 1;
}


.page-landing-title-name-letter-hide:nth-of-type(1) {
    transition-delay: 100ms, 100ms, 100ms;
    transition-property: max-height, opacity, transform;
}
.page-landing-title-name-letter-hide:nth-of-type(2) {
    transition-delay: 200ms;
}
.page-landing-title-name-letter-hide:nth-of-type(3) {
    transition-delay: 300ms;
}
.page-landing-title-name-letter-hide:nth-of-type(4) {
    transition-delay: 400ms;
}
.page-landing-title-name-letter-hide:nth-of-type(5) {
    transition-delay: 500ms;
}
.page-landing-title-name-letter-hide:nth-of-type(6) {
    transition-delay: 600ms;
}
.page-landing-title-name-letter-hide:nth-of-type(7) {
    transition-delay: 700ms;
}
.page-landing-title-name-letter-hide:nth-of-type(8) {
    transition-delay: 800ms;
}
.page-landing-title-name-letter-hide:nth-of-type(9) {
    transition-delay: 900ms;
}
.page-landing-title-name-letter-hide:nth-of-type(10) {
    transition-delay: 1000ms;
}
.page-landing-title-name-letter-hide:nth-of-type(11) {
    transition-delay: 1100ms;
}


#page-landing-title-name:hover .page-landing-title-name-letter-hide{
    max-height: 200px;
    transform: scaleY(1);
}

#page-landing-title-container-description {
    font-size: 1.2rem;
    position: absolute;
    top: -35px;
    left: -25vw;
    text-align: justify;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    #page-landing-title-container-table {
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
    }

    #page-landing-image {
        position: absolute;
        width: 70vw;
        left: 0;
        top: 15vh;
    }

    #page-landing-title-container-description {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .page-landing-title-container-row-2 {
        position: absolute;
    }
    .page-landing-title-container-row-1 {
        position: absolute;
    }
    .page-landing-title-container-column-2 {
        position: absolute;
    }
    .page-landing-title-container-column-1 {
        position: absolute;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}