#menu-container {
    position: relative;
}
#menu-list-item-tl {
    position: fixed;
    top: 40px;
    left: 40px;
    padding: 4px 0 0 4px;
    border-top: 1px solid var(--color-secondary);
    border-left: 1px solid var(--color-secondary);
}

#menu-list-item-tr {
    position: fixed;
    top: 40px;
    right: 40px;
    padding: 5px 5px 0 0;
}

#menu-list-item-bl {
    position: fixed;
    bottom: 40px;
    left: 40px;
    padding: 0 0 4px 4px;
    border-bottom: 1px solid var(--color-secondary);
    border-left: 1px solid var(--color-secondary);
}

#menu-list-item-br {
    position: fixed;
    bottom: 40px;
    right: 40px;
    padding: 0 4px 4px 0;
    border-bottom: 1px solid var(--color-secondary);
    border-right: 1px solid var(--color-secondary);
}

#menu-list a {
    text-decoration: none;
}

#menu-list {
    list-style: none;
}

#menu-list li {
    cursor: pointer;
    z-index: 999;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    #menu-list-item-tl, #menu-list-item-bl, #menu-list-item-br{
        display: none;
    }

    #menu-list-item-tr {
        right: 20px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    #menu-container {
        font-size: 1.6rem;
    }
}