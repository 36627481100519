#page-resume-container {
    width: 100vw;
    height: fit-content;
    position: relative;
    background-color: var(--color-background);
    display: flex;
    justify-content: center;
    align-items: center;
}

#resume-timeline-line {
    width: 6px;
    height: 100%;
    background-color: var(--color-secondary);
}

#resume-timeline-container-upper {
    width: 70vw;
    height: fit-content;
    background-color: red;
}

#resume-timeline-container {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    
}

#resume-timeline-container-column-1 {
    width: 15%;
    height: 100%;
    text-align: flex-end;
}
#resume-timeline-date-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-self: space-between;
    height: 100%;
}
#resume-timeline-date-list h3 {
    font-size: 2rem;
}
#resume-timeline-date-list li {
    height: 20%;
    padding-right: 10px;
}

#resume-timeline-date-list li:nth-of-type(3) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#resume-timeline-date-list li:nth-of-type(4),
#resume-timeline-date-list li:nth-of-type(5) {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

#resume-timeline-container-column-2 {
    width: 85%;
    height: 100%;
    display: flex;
}

#resume-timeline-entry-list {
    list-style: none;
    width: 100%;
    height: 100%;
    position: relative;
}

#resume-timeline-entry-list li {
    display: flex;
    height: 20%;
    width: 100%;
    position: relative;
}

.resume-timeline-entry-item-block {
    margin-left: 10px;
    width: 40px;
    height: 80px;
    background-color: var(--color-primary);
    z-index: 997;
    position: absolute;
    transition: all 1s ease;
}

.resume-timeline-entry-item-block:hover {
    width: 100%;
}

.resume-timeline-entry-item {
    z-index: 998;
    position: absolute;
    left: 50%;
    margin: 5px 0;
    color: var(--color-background);
}

#resume-timeline-entry-list li:nth-of-type(3) {
    align-items: center;
}

#resume-timeline-entry-list li:nth-of-type(4),
#resume-timeline-entry-list li:nth-of-type(5) {
    align-items: flex-end;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

}