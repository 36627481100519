#page-footer-container {
    width: 100vw;
    height: 20vh;
    position: absolute;
    bottom: -20vh;
    background-color: rgb(21, 21, 22);
    color: rgb(89, 88, 85);
}

.table {
    position: absolute;
    height: 70%;
    width: 70%;
    top: 15%;
    left: 15%;
    display: flex;
}

.f-table-column-1 {
    position: relative;
    height: 100%;
    width: 15%;
}

.f-table-column-2 {
    position: relative;
    height: 100%;
    width: 70%;
    padding-left: 25vw;
}